import React, { ReactNode } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import {
  Box,
  Mq,
  Text,
  H2,
  getResponsiveProp
} from '@parsleyhealth/cilantro-ui';
import SanityImage from '../NewSanityImage';
import { SanityBasicImage } from 'graphql-types';

interface BlogPostHeroCardProps {
  title: string;
  category?: string;
  author: ReactNode;
  url: string;
  image: SanityBasicImage;
}

const Container = styled(Box)`
  flex-direction: column;
  cursor: pointer;

  ${Mq(2)} {
    flex-direction: row;
  }
`;

const Column = styled(({ size, ...rest }) => <Box {...rest} />)`
  width: 100%;
  position: relative;
  overflow: hidden;

  ${Mq(2)} {
    width: ${({ size }) => size}%;
  }
`;

const ImageContainer = styled(Box)`
  width: 100%;
  position: relative;
  top: 0;
  bottom: 0;
  margin-bottom: -2px;

  img,
  div {
    height: auto;
    width: 100%;
  }

  ${Mq(2)} {
    position: absolute;
    margin-bottom: 0;

    img,
    div {
      height: 100%;
    }
  }
`;

const BlogPostHeroCard = ({
  title,
  category = '',
  image,
  author,
  url
}: BlogPostHeroCardProps) => {
  return (
    <Container display="flex" onClick={() => navigate(url)}>
      <Column size={60}>
        <Box width="100%" height="100%" position="relative">
          {image && (
            <ImageContainer>
              <SanityImage
                image={image}
                imageSizes={getResponsiveProp('100vw', '60vw')}
                coverContainer
              />
            </ImageContainer>
          )}
        </Box>
      </Column>
      <Column size={40} bg="leafyGreen" pl="l" py="xxl" pr="s">
        <Box mb="xl">
          <Text variant="pBoldSmall" color="white" mb="s">
            {category.replace('&amp;', '&').toLocaleUpperCase()}
          </Text>
          <H2 color="white" mb="m">
            {title}
          </H2>
          <Box>{author}</Box>
        </Box>
      </Column>
    </Container>
  );
};

export default BlogPostHeroCard;
