const truncateDescription = (description: any[], textCap: number) => {
  if (!description?.length) return description;
  // const child = description[0].children[0];
  const text = description.reduce((acc1, descriptionItem) => {
    const descText = descriptionItem?.children?.reduce(
      (acc2: string, child: any) => {
        const childText = child.text;
        return `${acc2} ${childText}`;
      },
      ''
    );
    return `${acc1} ${descText}`;
  }, '');

  const trimmedText =
    text.length >= textCap
      ? text
          .slice(0, textCap)
          .trim()
          .concat('...')
      : text;
  return [
    {
      ...description[0],
      style: 'div',
      children: [
        {
          marks: [],
          _key: description[0].children[0]._key,
          _type: 'span',
          text: trimmedText
        }
      ]
    }
  ];
};

export default truncateDescription;
