import React, { ReactNode } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { Box, Text, theme } from '@parsleyhealth/cilantro-ui';

interface BlogPostCardProps {
  image: ReactNode;
  title: string;
  description: string | ReactNode;
  author?: ReactNode;
  category?: string;
  url: string;
  simpleCard?: boolean;
}

const Container = styled(Box)`
  .title {
    transition: 0.2s;
  }

  &:hover .title {
    color: ${theme.colors.leafyGreen};
  }
`;

const BlogPostCard = ({
  image,
  title,
  description,
  author,
  category,
  url,
  simpleCard
}: BlogPostCardProps) => {
  const onClick = () => navigate(url);
  const titleVariant = simpleCard ? 'pBoldLarge' : 'pBold';
  const showDescription = !simpleCard;
  return (
    <Container cursor="pointer" onClick={onClick}>
      <Box
        height={250}
        mb="xs"
        overflow="hidden"
        position="relative"
        width="100%"
      >
        {image}
      </Box>
      {category && (
        <Text color="textGray" variant="pBoldSmall" mb="xs">
          {category.replace('&amp;', '&').toLocaleUpperCase()}
        </Text>
      )}
      <Text mb="s" variant={titleVariant} className="title">
        {title}
      </Text>
      {showDescription && <Text mb="s">{description}</Text>}
      {author && <Box>{author}</Box>}
    </Container>
  );
};

export default BlogPostCard;
